import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Card,
  Grid,
} from '@material-ui/core';
import {
  Email
} from '@material-ui/icons';
import Avatar from '@material-ui/core/Avatar';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
  },
  card: {
    minHeight: 90,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  grid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  doubleCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  email: {
    width: 17,
    marginRight: theme.spacing(0.5),
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(1)
  },
  chip: {
    borderRadius: 15,
    padding: theme.spacing(1),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
    display: 'block',
    backgroundColor: '#960d3b',
    fontSize: 12,
    fontWeight: 600,
    color: 'white',
    '&:hover': {
      backgroundColor: '#860b34',
    },
    longText: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  }
}));

const ContactCard = ({ contact }) => {
  const classes = useStyles();

  return (
    contact
      ? <Link to={'/profile/' + encodeURIComponent(contact.email)} className={classes.link}>
        <Card className={classes.card}>

          <div className={classes.container}>
            <Grid container className={classes.grid}>
                <Grid item xs={2}>
                  <Avatar alt={contact.displayName} src={contact.photoUrl} />
                </Grid>
                <Grid item xs={8} className={classes.column}>
                  <Typography>{contact.displayName}</Typography>
                  <div className={classes.doubleCenter}>
                    <Email className={classes.email}/>
                    <Typography variant="caption" className={classes.longText}>{contact.email}</Typography>
                  </div>
                </Grid>
            </Grid>

            <Grid container className={classes.grid}>
              {contact.tags.slice(0, 6).map(tag =>
                  <div key={tag.id} className={classes.chip}>{tag.value}</div>
              )}
            </Grid>
          </div>
        </Card>
      </Link>
      : null
  );
};

export default ContactCard;
