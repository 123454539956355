import React, { useState } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton,
  Grid,
  Chip
} from '@material-ui/core';
import {
  ExpandMore,
  ExpandLess,
  Cancel,
} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import TagList from './TagList';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: theme.spacing(2, 0, 2, 0),
  },
  accordion: {
    backgroundColor: '#D2E7EC',
    maxWidth: 600,
  },
  link: {
    textDecoration: 'none',
    color: 'black'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  summary: {
    position: 'relative',
  },
  summaryGrid: {
    width: 'calc(100% - 60px)'
  },
  expandMore: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 3,
    color: 'rgba(0, 0, 0, 0.54)',
  },
  chip: {
    backgroundColor: 'white'
  }
}));

const GroupContact = (props) => {
  const classes = useStyles();
  const modifAllowed = props.modifAllowed;
  const memberEmail = props.memberEmail;
  const group = props.group;
  const setReload = props.setReload;
  const [expanded, setExpanded] = useState(false);

  const handleDelete = async () => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/contact/deleteGroup/${memberEmail}/${group.email}`);
      if (!response.data) throw Error(response.statusText);
      setReload(reload => !reload);
    } catch (error) {
      console.warn(error);
    }
  };

  return (
    <div className={classes.root}>
      <Accordion expanded={modifAllowed ? expanded : false} onChange={(_, isExpanded) => modifAllowed && setExpanded(isExpanded)} className={classes.accordion}>
        <AccordionSummary
          className={classes.summary}
        >
          <Grid container spacing={1} alignItems='center' className={classes.summaryGrid}>
            {modifAllowed &&
              <Grid item>
                <IconButton size='small' onClick={handleDelete}>
                  <Cancel />
                </IconButton>
              </Grid>
            }

            <Grid item>
              <Link to={'/profile/' + encodeURIComponent(group.email)} className={classes.link}>
                <Typography className={classes.heading}>{group.email}</Typography>
              </Link>
            </Grid>

            {!expanded && group.tags && group.tags.map((tag) =>
              <Grid item key={tag.id}><Chip label={tag.value} size='small' className={classes.chip}/></Grid>
            )}
          </Grid>
          {modifAllowed &&
            <IconButton className={classes.expandMore}>
              {expanded ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          }
        </AccordionSummary>

        <AccordionDetails>
          <TagList modifAllowed={modifAllowed} createTagAllowed={modifAllowed} list={group.tags} entityType={'contact'} entityId={group.email} setReload={setReload}/>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default GroupContact;
