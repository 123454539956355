import { useEffect } from 'react';
const LoginPage = () => {
  // This page is a redirection to the CAS login service
  useEffect(() => {
    window.location.href = `${process.env.REACT_APP_LOGIN_URL}?service=${process.env.REACT_APP_LOGIN_SERVICE}`;
  }, []);
  return null;
};

export default LoginPage;
