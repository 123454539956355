import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  TextField,
  Button,
  Typography,
  Select,
  MenuItem
} from '@material-ui/core';
import axios from 'axios';
import DialogInfos from '../General/DialogInfos';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '90%',
    },
    [theme.breakpoints.up('md')]: {
      width: '70%',
    },
  },
  textField: {
    marginTop: theme.spacing(2),
    width: '100%'
  },
  button: {
    marginTop: theme.spacing(1),
    textTransform: 'none',
    backgroundColor: '#960d3b',
    color: 'white',
    '&:hover': {
      backgroundColor: '#860b34',
    },
  },
  groupedItem: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginTop: theme.spacing(1)
  },
  select: {
    marginLeft: theme.spacing(1)
  }
}));

const AddBlock = (props) => {
  const classes = useStyles();
  const setReload = props.setReload;
  const questionId = props.questionId;
  const infos = props.infos;
  const [textFieldValue, setTextFieldValue] = useState('');
  const [expirationYear, setExpirationYear] = useState('Jamais');

  const createAnswer = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/question/createAnswer`, {
        questionId: questionId, answerString: textFieldValue, userEmail: infos.email, expirationDate: expirationYear === 'Jamais' ? null : new Date(expirationYear, 6, 31)
      });
      setReload(reload => !reload);
      setTextFieldValue('');
    } catch (error) {
      console.warn(error);
    }
  };

  const handleTextFieldChange = (e) => {
    setTextFieldValue(e.target.value);
  };

  return (
      <div className={classes.root}>
        <div className={classes.form}>
          <TextField className={classes.textField}
            label="Ajout d'une réponse"
            multiline='true'
            rows={3}
            rowsMax={5}
            variant="outlined"
            value={textFieldValue}
            onChange={handleTextFieldChange}
          />
          <div className={classes.groupedItem}>
            <Typography variant='caption'>
              Réponse à réviser {expirationYear === 'Jamais' ? '' : ' à la rentrée '}
            </Typography>
            <Select
              value={expirationYear}
              onChange={(e) => setExpirationYear(e.target.value)}
              className={classes.select}
            >
              <MenuItem value={'Jamais'}>Jamais</MenuItem>
              <MenuItem value={2022}>2022</MenuItem>
              <MenuItem value={2023}>2023</MenuItem>
              <MenuItem value={2024}>2024</MenuItem>
              <MenuItem value={2025}>2025</MenuItem>
              <MenuItem value={2026}>2026</MenuItem>
            </Select>
            <DialogInfos subject={'expirationDateAnswer'} />
          </div>
        </div>
        <Button
          variant="contained"
          className={classes.button}
          onClick={createAnswer}
        >
          Envoyer ma réponse
        </Button>
    </div>
  );
};

export default AddBlock;
