import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  Chip,
} from '@material-ui/core';
import AddTag from './AddTag';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0, 2, 0),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  chip: {
    backgroundColor: '#960D3B26', // C9A8B3 960D3B26 EED4DC D8D5DD
  },
  list: {
    marginBottom: theme.spacing(1)
  }
}));

const TagList = ({ createTagAllowed, tags, setTags, label }) => {
  const classes = useStyles();

  const handleAddTag = (newTag) => {
    if (newTag.representative) {
      setTags([...tags, newTag.representative]);
    } else {
      setTags([...tags, newTag]);
    }
  };
  const handleDeleteTag = (deletedTagValue) => {
    setTags(tags.filter(({ value }) => value !== deletedTagValue));
  };

  return (
    <Box className={classes.root}>
      <Grid container spacing={1} alignItems='center' className={classes.list}>
        {tags && tags.map((tag) =>
          <Grid item key={tag.id}>
            <Chip label={tag.value} onDelete={() => handleDeleteTag(tag.value)} className={classes.chip} />
          </Grid>
        )}
      </Grid>
      <AddTag createTagAllowed={createTagAllowed} handleAddTag={handleAddTag} label={label} />
    </Box>
  );
};

export default TagList;
