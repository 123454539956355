import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import AuthContext from './auth.context';
import Topbar from '../General/Topbar';
import NavTabs from '../General/NavTabs';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useContext(AuthContext);
  return <Route {...rest} component={(props) =>
    isAuthenticated
      ? <div>
          <Topbar />
          <NavTabs />
          <Component {...props} />
      </div>
      : <Redirect to="/login"/>

  }/>;
};
export default PrivateRoute;
