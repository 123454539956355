import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Avatar, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0, 2, 0),
  },
  person: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textDecoration: 'none',
    color: 'black',
  },
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    marginBottom: 5,
  },
}));

const MemberList = (props) => {
  const classes = useStyles();
  const contactList = props.contactList;
  const [members, setMembers] = useState([]);

  const fetchWebserviceMembers = async () => {
    const newMembers = [];
    for (let i = 0; i < contactList.length; i++) {
      const memberEmail = contactList[i].email;
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/contact/webservice/${memberEmail}`
        );
        if (!response.data) throw Error(response.statusText);
        if (response.data[0]) newMembers.push(await response.data[0]);
      } catch (error) {
        if (error.response.status === 401) {
          localStorage.clear();
        }
        console.warn(error);
      }
    }
    setMembers(newMembers);
  };

  useEffect(() => {
    fetchWebserviceMembers();
  }, []);

  return (
    <Box className={classes.root}>
      <Grid container spacing={3} alignItems="start">
        {members.slice(0, 10).map((member, index) => (
          <Grid item key={index}>
            <Link
              className={classes.person}
              to={'/profile/' + encodeURIComponent(member.mail)}
            >
              <Avatar
                alt={member.displayName}
                src={member.photoUrl}
                className={classes.avatar}
              />
              <Typography variant="caption">
                {member.displayName.split(' ')[0]}
              </Typography>
              <Typography variant="caption">
                {member.displayName.split(' ').slice(1).join(' ')}
              </Typography>
            </Link>
          </Grid>
        ))}
        {members.length > 10 && (
          <Grid item>
            <Avatar className={classes.avatar}>+{members.length - 10}</Avatar>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default MemberList;
