import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import {
  TextField,
  Breadcrumbs,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    width: 300,
    [theme.breakpoints.down('xs')]: {
      width: 250,
    },
  },
}));

const filter = createFilterOptions();

const AddTag = ({ createTagAllowed, handleAddTag, label, fetchTags }) => {
  const classes = useStyles();
  const [newTag, setNewTag] = useState(null);
  const [allTags, setAllTags] = useState([]);

  const fetchOptions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/tag`
      );
      if (!response.data) throw Error(response.statusText);

      const data = await response.data;
      setAllTags(data);
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear();
      }
      console.warn(error);
    }
  };

  useEffect(async () => {
    fetchOptions();
  }, [fetchTags]);

  useEffect(async () => {
    if (newTag) {
      await handleAddTag(newTag);
    }
  }, [newTag]);

  return (
    <Autocomplete
      value={newTag}
      onChange={(event, newValue) => {
        if (newValue) {
          if (newValue.inputValue) {
            // Create a new newTag from the user input
            setNewTag({
              value: newValue.inputValue,
            });
          } else {
            setNewTag(newValue);
          }
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new newTag
        if (createTagAllowed && params.inputValue !== '') {
          filtered.push({
            inputValue: params.inputValue,
            value: `Ajouter '${params.inputValue}'`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-with-text-demo"
      options={allTags}
      getOptionLabel={(option) => {
        // Add 'xxx' option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.value;
      }}
      renderOption={(option) => {
        if (option.representative) {
          return (
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
              <Typography color="textPrimary">{option.value}</Typography>
              <Typography color="textPrimary">
                {option.representative.value}
              </Typography>
            </Breadcrumbs>
          );
        }
        return option.value;
      }}
      className={classes.root}
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          label={label !== undefined ? label : 'Ajouter un tag'}
          variant="outlined"
        />
      )}
    />
  );
};

export default AddTag;
