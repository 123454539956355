import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Card,
  Grid,
} from '@material-ui/core';
import logo from '../../images/logo-cs.png';

const useStyles = makeStyles((theme) => ({
  card: {
    minHeight: 90,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  grid: {
    display: 'flex',
    alignItems: 'center',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  chip: {
    borderRadius: 15,
    padding: theme.spacing(1),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
    display: 'block',
    backgroundColor: '#960d3b',
    fontSize: 12,
    fontWeight: 600,
    color: 'white',
    '&:hover': {
      backgroundColor: '#860b34',
    }
  },
  link: {
    textDecoration: 'none',
    color: 'black',
  },
  img: {
    width: 45
  },
}));

const WebsiteCard = ({ website }) => {
  const classes = useStyles();

  return (
    website
      ? <Card className={classes.card}>

        <div className={classes.container}>
          <a href={website.link} target='_blank' rel='noreferrer' className={classes.link}>
          <Grid container className={classes.grid}>
              <Grid item xs={3}>
              <img src={logo} alt="logo" className={classes.img} />
              </Grid>
              <Grid item xs={8} className={classes.column}>
                <Typography>{website.title}</Typography>
              </Grid>
          </Grid>
          </a>

          <Grid container className={classes.grid}>
            {website.tags.slice(0, 6).map(tag =>
                <div className={classes.chip} key={tag.id}>{tag.value}</div>
            )}
          </Grid>
        </div>
      </Card>
      : null
  );
};

export default WebsiteCard;
