import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Button,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { ExpandMore } from '@material-ui/icons';
import axios from 'axios';
import AuthContext from '../Auth/auth.context';

import QuestionCard from './QuestionCard';
import AnswerCard from './AnswerCard';
import AddBlock from './AddAnswerBlock';
import DialogInfos from '../General/DialogInfos';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: theme.spacing(2),
  },
  button: {
    textTransform: 'none',
  },
  cardContainer: {
    margin: theme.spacing(2),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '90%',
    },
    [theme.breakpoints.up('md')]: {
      width: '70%',
    },
  },
  accordion: {
    backgroundColor: 'rgb(239, 218, 225)',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '90%',
    },
    [theme.breakpoints.up('md')]: {
      width: '70%',
    },
    marginBottom: theme.spacing(3),
  },
  containerAlertButton: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonToAlert: {
    textTransform: 'none',
    backgroundColor: 'rgb(255, 244, 229)',
    color: 'rgb(102, 60, 0)',
    '&:hover': {
      backgroundColor: 'rgb(255, 244, 229)',
    },
  },
}));

const QAPage = () => {
  const classes = useStyles();
  const questionId = parseInt(useLocation().pathname.split('/')[2]);
  const [question, setQuestion] = useState(null);
  const [expanded, setExpanded] = useState(true);
  const [reload, setReload] = useState(false);
  const { infos } = useContext(AuthContext);

  const fetchQuestion = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/question/fetchquestion/${questionId}`
      );
      if (!response.data) throw Error(response.statusText);

      const data = await response.data;
      const newData = addVoteCounts(data);
      setQuestion(newData);
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear();
      }
      console.warn(error);
    }
  };

  const handleExpired = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/question/save`,
        { id: question.id, expired: !question.expired }
      );
      if (!response.data) throw Error(response.statusText);
      setReload((reload) => !reload);
    } catch (error) {
      console.warn(error);
    }
  };

  const addVoteCounts = (data) => {
    let upvotes = 0;
    let downvotes = 0;
    for (const index in Object.keys(data.votes)) {
      const vote = data.votes[index];
      if (vote.up) {
        upvotes = upvotes + 1;
      } else {
        downvotes = downvotes + 1;
      }
    }
    for (const index in Object.keys(data.answers)) {
      const answer = data.answers[index];
      let answerUpvotes = 0;
      let answerDownvotes = 0;
      for (const _index in Object.keys(answer.votes)) {
        const vote = answer.votes[_index];
        if (vote.up) {
          answerUpvotes = answerUpvotes + 1;
        } else {
          answerDownvotes = answerDownvotes + 1;
        }
      }
      data.answers[index].upvotes = answerUpvotes;
      data.answers[index].downvotes = answerDownvotes;
    }
    data.upvotes = upvotes;
    data.downvotes = downvotes;
    return data;
  };

  useEffect(() => {
    fetchQuestion();
  }, [reload]);

  return question ? (
    <div className={classes.root}>
      {question.expired && (
        <Alert
          severity="warning"
          action={
            infos.isAdmin ? (
              <Button
                onClick={handleExpired}
                color="inherit"
                size="small"
                className={classes.button}
              >
                Supprimer
              </Button>
            ) : null
          }
        >
          <AlertTitle>Avertissement fiabilité</AlertTitle>
          Les réponses de ces pages ne sont peut-être plus valables.{' '}
          <strong>Vérifiez les informations</strong> en attendant une mise à
          jour.
        </Alert>
      )}
      <div className={classes.cardContainer}>
        <QuestionCard question={question} infos={infos} setReload={setReload} />
      </div>
      <Accordion
        className={classes.accordion}
        expanded={expanded}
        onChange={(_, isExpanded) => setExpanded(isExpanded)}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>Réponses</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} className={classes.grid}>
            {question.answers.map((answer, index) => (
              <Grid item key={index} xs={12}>
                <AnswerCard
                  answer={answer}
                  setReload={setReload}
                  infos={infos}
                />
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
      {!question.expired && (
        <div className={classes.containerAlertButton}>
          <Button
            onClick={handleExpired}
            className={classes.buttonToAlert}
            variant="outlined"
          >
            Signaler une mise à jour nécessaire
          </Button>
          <DialogInfos subject={'alertUpdateNeeded'} />
        </div>
      )}
      <AddBlock questionId={questionId} setReload={setReload} infos={infos} />
    </div>
  ) : null;
};

export default QAPage;
