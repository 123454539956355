import { useEffect, useState } from 'react';
import axios from 'axios';

export const useAuth = () => {
  const [auth, setAuth] = useState(null);

  useEffect(() => {
    const storedAuth = localStorage.getItem('auth');
    if (storedAuth) {
      setAuth(JSON.parse(storedAuth));
      axios.defaults.headers.common.Authorization = `Bearer ${JSON.parse(storedAuth).jwt}`;
    } else {
      setAuth({
        isAuthenticated: false,
        jwt: null,
        infos: null,
      });
    }
  }, []);

  return { auth, setAuth };
};
