import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Card, Switch } from '@material-ui/core';
import TagList from './TagList';
import MemberList from './MemberList';
import GroupContact from './GroupContact';
import AddGroup from './AddGroup';
import SearchColleague from './SearchColleague';
import AuthContext from '../Auth/auth.context';
import ProfileInfos from './ProfileInfos';
import DialogInfos from '../General/DialogInfos';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  globalCard: {
    padding: theme.spacing(3, 8, 3, 8),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3, 2, 3, 2),
    },
    margin: theme.spacing(3),
  },
  spacingBeforeDiv: {
    marginTop: theme.spacing(2),
  },
  sectionWithInfo: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  switchBase: {
    color: '#860b34',
    '&$checked': {
      color: '#860b34',
    },
    '&$checked + $track': {
      backgroundColor: '#860b34',
    },
  },
  checked: {
    color: '#860b34',
  },
  track: {
    backgroundColor: '#000',
  },
  smallText: {
    maxWidth: 600,
  },
}));

const ProfilePage = () => {
  const classes = useStyles();
  const { emailEncode } = useParams();
  const email = decodeURIComponent(emailEncode);
  const { infos } = useContext(AuthContext);
  const modifAllowed = infos.isAdmin || infos.status !== 'student';
  const [webserviceExists, setWebserviceExists] = useState(true);
  const [contactExists, setContactExists] = useState(true);
  const [profile, setProfile] = useState({});
  const [contact, setContact] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const [reload, setReload] = useState(false);

  const fetchWebserviceInfo = async (emailToSearch) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/contact/webservice/${emailToSearch}`
      );
      if (!response.data) throw Error(response.statusText);

      const data = await response.data;
      if (data.length > 0) {
        let buildingName = '';
        if (data[0].buildingName === 'Saclay-A') {
          buildingName = 'Campus de Saclay - Bâtiment Eiffel';
        } else if (data[0].buildingName === 'Saclay-B') {
          buildingName = 'Campus de Saclay - Bâtiment Bouygues';
        } else if (data[0].buildingName === 'Saclay-S') {
          buildingName = 'Campus de Saclay - Bâtiment Bréguet';
        } else if (data[0].buildingName === 'Rennes') {
          buildingName = 'Campus de Rennes';
        } else if (data[0].buildingName === 'Metz') {
          buildingName = 'Campus de Metz';
        }
        setProfile({ ...data[0], buildingName });
      } else {
        setProfile({ exists: false });
        setWebserviceExists(false);
      }
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear();
      }
      console.warn(error);
    }
  };

  const fetchContact = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/contact/${email}`
      );

      const data = await response.data;
      if (data) {
        setContact(data);
      } else {
        setContact({ exists: false });
        setContactExists(false);
      }
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear();
      }
      console.warn(error);
    }
  };

  const fetchIsAdmin = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/${email}`
      );
      const data = await response.data;
      if (data) {
        setIsAdmin(data.isAdmin);
      }
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear();
      }
      console.warn(error);
    }
  };

  const handleAdminChange = async (event) => {
    setIsAdmin(event.target.checked);
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/user`, {
        email: email,
        isAdmin: event.target.checked,
      });
    } catch (error) {
      console.warn(error);
    }
  };

  useEffect(() => {
    fetchWebserviceInfo(email);
    fetchContact();
    fetchIsAdmin();
  }, [reload]);

  return Object.keys(profile).length !== 0 && Object.keys(contact) !== 0 ? (
    <div className={classes.root}>
      <div className={classes.container}>
        <SearchColleague />
        {webserviceExists || contactExists ? (
          <Card className={classes.globalCard}>
            <ProfileInfos
              profile={profile}
              webserviceExists={webserviceExists}
              email={email}
            />

            <div className={classes.sectionWithInfo}>
              <Typography variant="h6" className={classes.spacingBeforeDiv}>
                Tags
              </Typography>
              {modifAllowed && <DialogInfos subject={'tagForProfile'} />}
            </div>
            <TagList
              modifAllowed={modifAllowed}
              createTagAllowed={modifAllowed}
              list={contact.tags}
              entityType={'contact'}
              entityId={email}
              setReload={setReload}
            />

            {contact.members && contact.members.length > 0 && (
              <div className={classes.spacingBeforeDiv}>
                <Typography variant="h6">Membres</Typography>
                <MemberList contactList={contact.members} />
              </div>
            )}

            <div className={classes.spacingBeforeDiv}>
              <div className={classes.sectionWithInfo}>
                <Typography variant="h6" className={classes.spacingBeforeDiv}>
                  Adresses génériques
                </Typography>
                {modifAllowed && (
                  <DialogInfos subject={'groupContactForProfile'} />
                )}
              </div>
              {contact.groups &&
                contact.groups.map((group, index) => (
                  <GroupContact
                    key={index}
                    modifAllowed={modifAllowed}
                    memberEmail={email}
                    group={group}
                    setReload={setReload}
                  />
                ))}
              {modifAllowed && (
                <AddGroup member={contact} setReload={setReload} />
              )}
              {!modifAllowed &&
                (!contact.groups || contact.groups.length === 0) && (
                  <Typography>Aucune adresse générique associée</Typography>
                )}
            </div>

            {webserviceExists && (
              <div className={classes.spacingBeforeDiv}>
                <div className={classes.sectionWithInfo}>
                  <Typography variant="h6" className={classes.spacingBeforeDiv}>
                    Description
                  </Typography>
                  {modifAllowed && (
                    <DialogInfos subject={'descriptionForProfile'} />
                  )}
                </div>
                {profile.supannEntiteAffectation &&
                  typeof profile.supannEntiteAffectation === 'object' &&
                  profile.supannEntiteAffectation.map((affectation, index) => (
                    <Typography key={index}>{affectation}</Typography>
                  ))}
                {profile.supannEntiteAffectation &&
                  typeof profile.supannEntiteAffectation === 'string' && (
                    <Typography>{profile.supannEntiteAffectation}</Typography>
                  )}
                <Typography>{profile.title}</Typography>
                <div className={classes.smallText}>
                  <Typography>{profile.description}</Typography>
                </div>
              </div>
            )}

            {infos.isAdmin && (
              <Grid
                container
                alignItems="center"
                spacing={2}
                className={classes.spacingBeforeDiv}
              >
                <Grid item>
                  <Typography variant="h6">
                    Modérateur de la plateforme
                  </Typography>
                </Grid>
                <Grid item>
                  <Switch
                    checked={isAdmin}
                    onChange={handleAdminChange}
                    classes={{
                      switchBase: classes.switchBase,
                      checked: classes.checked,
                      track: classes.track,
                    }}
                  />
                </Grid>
              </Grid>
            )}
          </Card>
        ) : (
          <Card className={classes.globalCard}>
            <Typography align="center">
              L'email {email} ne correspond à aucun contact.
            </Typography>
          </Card>
        )}
      </div>
    </div>
  ) : null;
};

export default ProfilePage;
