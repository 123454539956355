import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import AuthContext from './auth.context';
const PublicRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useContext(AuthContext);
  return <Route {...rest} component={(props) =>
    !isAuthenticated
      ? <Component {...props} />
      : <Redirect to="/"/>

  }/>;
};
export default PublicRoute;
