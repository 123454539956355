import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Typography, TextField, Button, Switch } from '@material-ui/core';
import TagList from './TagList';
import axios from 'axios';
import AuthContext from '../Auth/auth.context';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center'
  },
  card: {
    [theme.breakpoints.up('md')]: {
      width: '60%',
      padding: theme.spacing(3, 10, 2, 10),
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    textAlign: 'center',
    margin: theme.spacing(3),
    padding: theme.spacing(3, 3, 2, 3),
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  button: {
    textTransform: 'none',
    width: 300,
    [theme.breakpoints.down('sm')]: {
      width: 200,
    },
    margin: '10px auto',
    fontWeight: 'bold',
    backgroundColor: '#960d3b',
    color: 'white',
    '&:hover': {
      backgroundColor: '#860b34',
    },
  },
  groupedItem: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  switchBase: {
    color: '#860b34',
    '&$checked': {
      color: '#860b34',
    },
    '&$checked + $track': {
      backgroundColor: '#860b34',
    },
  },
  checked: {
    color: '#860b34'
  },
  track: {
    backgroundColor: '#000'
  }
}));

const NewQuestionPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { infos } = useContext(AuthContext);
  const storedAuth = localStorage.getItem('auth');
  axios.defaults.headers.common.Authorization = `Bearer ${JSON.parse(storedAuth).jwt}`;
  const [question, setQuestion] = useState('');
  const [questionLong, setQuestionLong] = useState('');
  const [urgent, setUrgent] = useState(false);
  const [tags, setTags] = useState([]);
  const [displayDetails, setDisplayDetails] = useState(false);
  const [error, setError] = useState('');

  const createTag = async (value) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/tag`, { value: value });
      return res.data;
    } catch (error) {
      console.warn(error);
    }
  };

  const submit = async (e) => {
    e.preventDefault();
    if (question) {
      const tagIds = [];
      for (const tag of tags) {
        if (tag.id) {
          tagIds.push(tag.id);
        } else {
          const newTag = await createTag(tag.value);
          tagIds.push(newTag.id);
        }
      }
      const questionForm = {
        question,
        questionLong: questionLong.length > 0 ? questionLong : undefined,
        urgent,
        tagIds
      };
      try {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/question`, questionForm);
        history.push(`/qapage/${res.data.id}`);
      } catch (error) {
        console.log(error);
        setError('Erreur lors de la publication de la question, veuillez rééssayer');
      }
    } else {
      setError('Veuillez renseigner une question');
    }
  };

  return (
    <form className={classes.root}>
        <Card className={classes.card}>
            <Typography className={classes.title} variant="h4" >Poser une nouvelle question</Typography>
            <TextField label="Votre question" variant="outlined" value={question} onChange={e => setQuestion(e.target.value)} required/>
            <Button variant="contained" className={classes.button} onClick={() => setDisplayDetails(!displayDetails)}>{displayDetails ? 'Cacher les détails' : 'Donner des détails sur la question' }</Button>
            {displayDetails && (<TextField
                label="Détails sur la question"
                multiline
                rows={4}
                variant="outlined"
                value={questionLong}
                onChange={(e) => setQuestionLong(e.target.value)}
            />)}
            <TagList createTagAllowed={infos.isAdmin || infos.status !== 'student'} tags={tags} setTags={setTags} />
            <div className={classes.groupedItem}>
              <Switch
                checked={urgent}
                onChange={() => setUrgent(!urgent)}
                classes={{ switchBase: classes.switchBase, checked: classes.checked, track: classes.track }}
              />
              <Typography>La question {urgent ? 'est' : "n'est pas"} urgente</Typography>
            </div>
            <Button variant="contained" className={classes.button} onClick={submit}>Publier</Button>
            {error && <Typography>{error}</Typography>}
        </Card>
    </form>
  );
};

export default NewQuestionPage;
