import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Card,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
} from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import TagList from '../StaffProfile/TagList';
import SearchSites from './SearchSites';
import NewWebsiteForm from './NewWebsiteForm';
import AuthContext from '../Auth/auth.context';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  card: {
    margin: theme.spacing(3, 3, 1, 3),
    padding: theme.spacing(3),
  },
  spacedButton: {
    textTransform: 'none',
    color: '#960d3b',
    borderColor: '#960d3b',
    marginTop: theme.spacing(2),
  },
  horizontalAlign: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.up('xs')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  deleteButton: {
    textTransform: 'none',
    color: '#960d3b',
    borderColor: '#960d3b',
    alignSelf: 'flex-end',
    height: '40%',
    width: '100%',
  },
  editButton: {
    textTransform: 'none',
    color: '#960d3b',
    borderColor: '#960d3b',
    height: '40%',
    alignSelf: 'flex-end',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(1),
    },
  },
  buttonContainer: {
    display: 'flex',
    margin: theme.spacing(2, 0, 0, 0),
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'column',
      margin: theme.spacing(0, 0, 0, 2),
    },
  },
  cancelButton: {
    textTransform: 'none',
  },
}));

const SitesPage = () => {
  const classes = useStyles();
  const { infos } = useContext(AuthContext);
  const modifAllowed = infos.isAdmin;
  const [selectedSite, setSelectedSite] = useState();
  const [editedWebsite, setEditedWebsite] = useState();
  const [reload, setReload] = useState(false);
  const [displayForm, setDisplayForm] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const fetchSelectedWebsite = async (id) => {
    if (selectedSite || id) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/website/${
            id || selectedSite.id
          }`
        );
        setSelectedSite(response.data);
      } catch (error) {
        if (error.response.status === 401) {
          localStorage.clear();
        }
        console.warn(error);
      }
    }
  };

  const handleEdit = () => {
    setDisplayForm(true);
    if (selectedSite) {
      setEditedWebsite(selectedSite);
    }
  };

  const deleteWebsite = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/website/${selectedSite.id}`
      );
      setSelectedSite(null);
      setDialogOpen(false);
      setReload((reload) => !reload);
    } catch (error) {
      console.warn(error);
    }
  };

  useEffect(() => {
    fetchSelectedWebsite();
  }, [reload]);

  return modifAllowed ? (
    <div className={classes.root}>
      <div className={classes.container}>
        <SearchSites
          fetchSelectedWebsite={fetchSelectedWebsite}
          setSelectedSite={setSelectedSite}
          reload={reload}
        />
        {selectedSite && (
          <Card className={classes.card}>
            <div>
              <a href={selectedSite.link}>
                <Typography variant="h6" align="center">
                  {selectedSite.title}
                </Typography>
              </a>
            </div>
            <Typography variant="h6">Tags</Typography>
            <div className={classes.horizontalAlign}>
              <TagList
                modifAllowed={modifAllowed}
                createTagAllowed={modifAllowed}
                list={selectedSite.tags}
                entityType={'website'}
                entityId={selectedSite.id}
                setReload={setReload}
              />
              <div className={classes.buttonContainer}>
                <Button
                  variant="outlined"
                  className={classes.editButton}
                  endIcon={<Edit />}
                  onClick={handleEdit}
                >
                  Modifier
                </Button>
                <Button
                  variant="outlined"
                  className={classes.deleteButton}
                  endIcon={<Delete />}
                  onClick={() => setDialogOpen(true)}
                >
                  Supprimer
                </Button>
              </div>
            </div>
            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
              <DialogTitle>Voulez-vous vraimez supprimer ce site ?</DialogTitle>
              <DialogActions>
                <Button
                  onClick={() => setDialogOpen(false)}
                  className={classes.cancelButton}
                >
                  Annuler
                </Button>
                <Button
                  onClick={deleteWebsite}
                  className={classes.deleteButton}
                  variant="outlined"
                  autoFocus
                >
                  Supprimer
                </Button>
              </DialogActions>
            </Dialog>
          </Card>
        )}
        <Button
          variant="outlined"
          className={classes.spacedButton}
          onClick={() => {
            setDisplayForm(!displayForm);
            setEditedWebsite(null);
          }}
        >
          {displayForm ? 'Cacher le formulaire' : 'Ajouter un nouveau site'}
        </Button>
        {displayForm && (
          <NewWebsiteForm
            website={editedWebsite}
            fetchSelectedWebsite={fetchSelectedWebsite}
            setDisplayForm={setDisplayForm}
            setReload={setReload}
          />
        )}
      </div>
    </div>
  ) : (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography>
          {' '}
          Vous n'êtes pas autorisé à consulter cette page
        </Typography>
        <Link to="/">Retourner à l'accueil</Link>
      </div>
    </div>
  );
};

export default SitesPage;
