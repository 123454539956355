import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Grid,
  IconButton,
  Avatar,
} from '@material-ui/core';
import {
  Business,
  Email,
  Phone,
  PhoneAndroid,
  Room,
  LinkedIn,
  Twitter,
  YouTube
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  photo: {
    width: 200,
    height: 200,
    margin: theme.spacing(0, 5, 0, 0),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 0, 2, 0),
      width: 120,
      height: 120,
    }
  },
  top: {
    maxWidth: 600,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    }
  },
  contactInfosContainer: {
    padding: theme.spacing(2),
  },
  rowWithIcon: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  rowWithSocialMedia: {
    marginLeft: theme.spacing(2),
  },
  linkedin: {
    color: '#0a66c2',
    marginRight: theme.spacing(1),
  },
  twitter: {
    color: '#1b99ee',
    marginRight: theme.spacing(1),
  },
  youtube: {
    color: '#fa0000',
    marginRight: theme.spacing(1),
  },
}));

const ProfileInfos = (props) => {
  const classes = useStyles();
  const profile = props.profile;
  const webserviceExists = props.webserviceExists;
  const email = props.email;

  return (
    <div className={classes.top}>
      <Avatar alt={profile.displayName} src={profile.photoUrl} className={classes.photo}/>

      <Grid container spacing={1} className={classes.contactInfosContainer}>
        <Typography variant='h5'>{webserviceExists && profile.displayName}</Typography>
        <Grid item xs={12} className={classes.rowWithIcon}>
          <Email className={classes.icon} />
          <Typography>{email}</Typography>
        </Grid>
        {profile.telpro &&
          <Grid item xs={12} className={classes.rowWithIcon}>
            <Phone className={classes.icon} />
            <Typography>{profile.telpro}</Typography>
          </Grid>
        }
        {profile.mobilepro &&
          <Grid item xs={12} className={classes.rowWithIcon}>
            <PhoneAndroid className={classes.icon} />
            <Typography>{profile.mobilepro}</Typography>
          </Grid>
        }
        {profile.phone1 &&
          <Grid item xs={12} className={classes.rowWithIcon}>
            <PhoneAndroid className={classes.icon} />
            <Typography>{profile.phone1}</Typography>
          </Grid>
        }
        {profile.phone2 &&
          <Grid item xs={12} className={classes.rowWithIcon}>
            <PhoneAndroid className={classes.icon} />
            <Typography>{profile.phone2}</Typography>
          </Grid>
        }
        {profile.buildingName &&
          <Grid item xs={12} className={classes.rowWithIcon}>
            <Room className={classes.icon} />
            <Typography>{profile.buildingName}</Typography>
          </Grid>
        }
        {profile.signaletiqueOrienteur &&
          <Grid item xs={12} className={classes.rowWithIcon}>
            <Business className={classes.icon} />
            <Typography>{profile.signaletiqueOrienteur}</Typography>
          </Grid>
        }
        <Grid item xs={12} className={classes.rowWithSocialMedia}>
          {profile.linkedin &&
            <IconButton className={classes.linkedin} onClick={() => window.open(profile.linkedin, '_blank')}>
              <LinkedIn fontSize='large'/>
            </IconButton>
          }
          {profile.twitter &&
            <IconButton className={classes.twitter} onClick={() => window.open(`https://twitter.com/${profile.twitter}`, '_blank')}>
              <Twitter fontSize='large'/>
            </IconButton>
          }
          {profile.video &&
            <IconButton className={classes.youtube} onClick={() => window.open(profile.video, '_blank')}>
              <YouTube fontSize='large'/>
            </IconButton>
          }
        </Grid>
      </Grid>

    </div>
  );
};

export default ProfileInfos;
