import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import {
  TextField,
  InputAdornment,
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import ContactResults from './ContactResults';
import WebsiteResults from './WebsiteResults';
import QuestionResults from './QuestionResults';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 1, 3, 1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  searchArea: {
    marginBottom: theme.spacing(2),
  },
  searchField: {
    [theme.breakpoints.up('xs')]: {
      width: '90%',
    },
    [theme.breakpoints.up('md')]: {
      width: 700,
    },
    borderRadius: 30,
    borderColor: '#960d3b',
  },
  button: {
    textTransform: 'none',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
    },
    color: '#960d3b',
    borderColor: '#960d3b',
  },
  grid: {
    margin: theme.spacing(3, 0, 3, 0),
    width: '100%',
  },
  buttonContainer: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
    alignItems: 'center',
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(0, 1, 0, 1),
  },
  justifyContent: {
    display: 'flex',
    justifyContent: 'center',
  },
  spacedButton: {
    textTransform: 'none',
    color: '#960d3b',
    borderColor: '#960d3b',
    marginTop: theme.spacing(2),
  },
}));

const ResultsPage = () => {
  const classes = useStyles();
  const { search } = useParams();
  const history = useHistory();
  const [searchText, setSearchText] = useState(decodeURI(search));
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchResults, setSearchResults] = useState({
    contacts: [],
    questions: [],
    websites: [],
  });

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/api/tag/search?query=${searchText}`
      )
      .then((res) => {
        if (res.data) {
          setSearchResults(res.data);
          setLoading(false);
        }
      })
      .catch((e) => {
        setLoading(false);
        setError('Erreur lors de la recherche, veuillez réessayer');
        if (e.response.status === 401) {
          localStorage.clear();
        }
      });
    setLoading(false);
    setError('');
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Grid container className={classes.searchArea}>
          <Grid item xs={12} md={3}></Grid>
          <Grid item xs={12} md={5} className={classes.justifyContent}>
            <TextField
              variant="outlined"
              placeholder="Question, thème, document..."
              autoFocus
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              className={classes.searchField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search color="action" />
                  </InputAdornment>
                ),
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  history.push(`/results/${encodeURI(searchText)}`);
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={2} className={classes.buttonContainer}>
            <Button
              variant="outlined"
              className={classes.button}
              onClick={() => history.push(`/results/${encodeURI(searchText)}`)}
            >
              Rechercher
            </Button>
          </Grid>
        </Grid>

        {loading ? (
          <div className={classes.container}>
            <CircularProgress />
          </div>
        ) : error ? (
          <div className={classes.container}>
            <p>{error}</p>
          </div>
        ) : (
          <div>
            <Grid container spacing={3} className={classes.grid}>
              <Grid item xs={12} md={4}>
                <ContactResults contacts={searchResults.contacts} />
                <WebsiteResults websites={searchResults.websites} />
              </Grid>
              <Grid item xs={12} md={7}>
                <QuestionResults questions={searchResults.questions} />
              </Grid>
            </Grid>
          </div>
        )}
        <Grid item xs={12} md={4} className={classes.columnContainer}>
          <Typography align="center">
            Aucun résultat ne correspond à votre recherche ?{' '}
          </Typography>
          <Button
            variant="outlined"
            className={classes.spacedButton}
            onClick={() => history.push('/question/new')}
          >
            Poser une question
          </Button>
        </Grid>
      </div>
    </div>
  );
};

export default ResultsPage;
