import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import AuthContext from '../Auth/auth.context';
import {
  Card,
  Grid,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  Typography,
  Select,
  MenuItem,
} from '@material-ui/core';
import TagList from '../Question/TagList';
import { CheckBox, RadioButtonChecked } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '80%',
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
  checkedIcon: {
    color: '#860b34',
  },
  select: {
    marginLeft: theme.spacing(1),
  },
}));

const SearchFAQ = ({ setQuestions }) => {
  const classes = useStyles();
  const { infos } = useContext(AuthContext);
  const [tags, setTags] = useState([]);
  const [filters, setFilters] = useState({
    urgent: false,
    expired: false,
    notResolved: false,
    toRevise: false,
  });
  const [sort, setSort] = useState('date');
  const today = new Date();
  const [expirationYear, setExpirationYear] = useState(
    today.getMonth() < 6 ? today.getFullYear() : today.getFullYear() + 1
  );

  const handleChangeSort = (event) => {
    setSort(event.target.value);
  };

  const handleChangeFilter = (event) => {
    setFilters({ ...filters, [event.target.name]: event.target.checked });
  };

  const searchQuestions = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/question/search`,
        {
          urgent: filters.urgent,
          expired: filters.expired,
          notResolved: filters.notResolved,
          toRevise: filters.toRevise,
          expirationYear: expirationYear,
          sort: sort,
          tags: tags,
        }
      );
      const data = await response.data;
      if (data) {
        setQuestions(data);
      }
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear();
      }
      console.warn(error);
    }
  };

  useEffect(() => {
    searchQuestions();
  }, [tags, filters, sort, expirationYear]);

  return (
    <Card className={classes.card}>
      <Grid container spacing={3} alignItems="flex-start">
        <Grid item sm={6} lg={4}>
          <FormControl>
            <FormLabel>Tri</FormLabel>
            <RadioGroup value={sort} onChange={handleChangeSort}>
              <FormControlLabel
                value="date"
                control={
                  <Radio
                    checkedIcon={
                      <RadioButtonChecked className={classes.checkedIcon} />
                    }
                  />
                }
                label="Du plus récent au plus ancien"
              />
              <FormControlLabel
                value="dateInverse"
                control={
                  <Radio
                    checkedIcon={
                      <RadioButtonChecked className={classes.checkedIcon} />
                    }
                  />
                }
                label="Du plus ancien au plus récent"
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid container item sm={6} lg={4}>
          <FormControl>
            <FormLabel>Filtres</FormLabel>
            <FormGroup>
              {infos.isAdmin && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filters.urgent}
                      onChange={handleChangeFilter}
                      name="urgent"
                      checkedIcon={<CheckBox className={classes.checkedIcon} />}
                    />
                  }
                  label="Urgent"
                />
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filters.expired}
                    onChange={handleChangeFilter}
                    name="expired"
                    checkedIcon={<CheckBox className={classes.checkedIcon} />}
                  />
                }
                label="A mettre à jour"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filters.notResolved}
                    onChange={handleChangeFilter}
                    name="notResolved"
                    checkedIcon={<CheckBox className={classes.checkedIcon} />}
                  />
                }
                label="Non résolu"
              />
              {infos.isAdmin && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filters.toRevise}
                      onChange={handleChangeFilter}
                      name="toRevise"
                      checkedIcon={<CheckBox className={classes.checkedIcon} />}
                    />
                  }
                  label={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography>A réviser en</Typography>
                      <Select
                        value={expirationYear}
                        onChange={(e) => setExpirationYear(e.target.value)}
                        className={classes.select}
                      >
                        <MenuItem value={2022}>2022</MenuItem>
                        <MenuItem value={2023}>2023</MenuItem>
                        <MenuItem value={2024}>2024</MenuItem>
                        <MenuItem value={2025}>2025</MenuItem>
                        <MenuItem value={2026}>2026</MenuItem>
                      </Select>
                    </div>
                  }
                />
              )}
            </FormGroup>
          </FormControl>
        </Grid>

        <Grid item sm={6} lg={4}>
          <FormControl>
            <FormLabel>Recherche</FormLabel>
            <TagList
              createTagAllowed={false}
              tags={tags}
              setTags={setTags}
              label="Parcourir les tags"
            />
          </FormControl>
        </Grid>
      </Grid>
    </Card>
  );
};

export default SearchFAQ;
