import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  searchResults: {
    overflowY: 'hidden',
  },
}));

const SearchSites = ({ fetchSelectedWebsite, setSelectedSite, reload }) => {
  const classes = useStyles();
  const [newSite, setNewSite] = useState(null);
  const [allSites, setAllSites] = useState([]);

  const fetchOptions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/website`
      );
      if (!response.data) throw Error(response.statusText);
      setAllSites(
        response.data.map((website) => ({ ...website, value: website.title }))
      );
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear();
      }
      console.warn(error);
    }
  };

  useEffect(() => {
    fetchOptions();
  }, [reload]);

  return (
    <Autocomplete
      value={newSite}
      onChange={(event, newValue) => {
        if (newValue) {
          setNewSite(newValue);
          fetchSelectedWebsite(newValue.id);
        } else {
          setNewSite(null);
          setSelectedSite(null);
        }
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-with-text-demo"
      options={allSites}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.value;
      }}
      renderOption={(option) => {
        return (
          <div className={classes.searchResults}>
            <Typography variant="body1" color="textPrimary">
              {option.title}
            </Typography>
            <Typography variant="body2" color="textPrimary">
              {option.link}
            </Typography>
          </div>
        );
      }}
      style={{ width: 300, marginTop: 20 }}
      freeSolo
      renderInput={(params) => (
        <TextField {...params} label="Recherche un site" variant="outlined" />
      )}
    />
  );
};

export default SearchSites;
