import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';

const filter = createFilterOptions();

const AddGroup = (props) => {
  const member = props.member;
  const alreadyLinked = member.groups
    ? member.groups.map((group) => group.email)
    : [];
  const setReload = props.setReload;
  const [newGroup, setNewGroup] = useState(null);
  const [allGroupsContact, setAllGroupsContact] = useState([]);

  const fetchOptions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/contact/groups`
      );
      if (!response.data) throw Error(response.statusText);

      const data = await response.data;
      setAllGroupsContact(data);
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear();
      }
      console.warn(error);
    }
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  const addGroup = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/contact/addGroup/${member.email}/${newGroup.email}`
      );
      setReload((reload) => !reload);
    } catch (error) {
      console.warn(error);
    }
  };

  useEffect(() => {
    if (newGroup) {
      addGroup();
    }
  }, [newGroup]);

  return (
    <Autocomplete
      value={newGroup}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          setNewGroup({
            email: newValue,
          });
        } else if (newValue && newValue.inputValue) {
          // Create a new newGroup from the user input
          setNewGroup({
            email: newValue.inputValue,
          });
        } else {
          setNewGroup(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new newGroup
        if (params.inputValue !== '') {
          filtered.push({
            inputValue: params.inputValue,
            email: `Ajouter "${params.inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={allGroupsContact.filter(
        (group) => alreadyLinked.indexOf(group.email) === -1
      )}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.email;
      }}
      renderOption={(option) => option.email}
      style={{ width: 300, marginTop: 20 }}
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          label="Ajouter une adresse générique"
          variant="outlined"
        />
      )}
    />
  );
};

export default AddGroup;
