import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import WebsiteCard from './WebsiteCard';

const useStyles = makeStyles((theme) => ({
  accordion: {
    backgroundColor: '#8578944f',
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightRegular,
  },
  bubble: {
    borderRadius: 15,
    backgroundColor: 'white',
    color: 'black',
    width: 30,
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginRight: theme.spacing(1),
  },
}));

const WebsiteResults = ({ websites }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  return (
    <div>
      <Accordion className={classes.accordion} expanded={expanded} onChange={(_, isExpanded) => setExpanded(isExpanded)}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
        >
          <div className={classes.bubble}>{websites.length}</div>
          <Typography className={classes.heading}>Sites internet</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            {websites.length > 0
              ? websites.map((website) => (
              <Grid item xs={12} key={website.id}>
                <WebsiteCard website={website}/>
              </Grid>
              ))
              : (
              <Grid item xs={12}>
                <Typography>Aucun site ne correspond à votre recherche</Typography>
              </Grid>)}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default WebsiteResults;
