import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import {
  Typography,
  Card,
  Checkbox,
  Grid,
} from '@material-ui/core';
import {
  Forum,
  NotificationImportant,
  CheckCircle,
  Help,
  Warning,
  ThumbDown,
  ThumbDownOutlined,
  ThumbUp,
  ThumbUpOutlined,
} from '@material-ui/icons';
import AuthContext from '../Auth/auth.context';

const useStyles = makeStyles((theme) => ({
  card: {
    minHeight: 70,
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    }
  },
  textPart: {
    textDecoration: 'none',
    color: 'black',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginRight: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      width: '70%',
      marginRight: theme.spacing(0),
    },
  },
  forumIcon: {
    margin: theme.spacing(0, 2, 0, 1),
  },
  grid: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: 70,
    },
    [theme.breakpoints.up('md')]: {
      width: '30%'
    }
  },
  iconStatus: {
    display: 'flex',
    alignItems: 'center',
    height: 42,
    justifyContent: 'center',
  },
  thumbIcons: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
    }
  },
  captions: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
    }
  },
  title: {
    fontSize: 15,
    [theme.breakpoints.up('md')]: {
      fontSize: 16
    },
  }
}));

const QuestionCard = ({ question }) => {
  const classes = useStyles();
  const { infos } = useContext(AuthContext);
  const [upVote, setUpVote] = useState(false);
  const [downVote, setDownVote] = useState(false);
  const [upVoteCount, setUpVoteCount] = useState(0);
  const [downVoteCount, setDownVoteCount] = useState(0);

  const addVoteCounts = () => {
    let upvotes = 0;
    let downvotes = 0;
    for (const index in Object.keys(question.votes)) {
      const vote = question.votes[index];
      if (vote.up) { upvotes = upvotes + 1; } else { downvotes = downvotes + 1; }
    };
    setUpVoteCount(upvotes);
    setDownVoteCount(downvotes);
  };

  const getUpvoteInfo = () => {
    for (const index in Object.keys(question.votes)) {
      const vote = question.votes[index];
      const email = vote.user.email;
      if (infos.email === email) {
        setUpVote(vote.up);
        setDownVote(!vote.up);
        break;
      }
    };
  };

  useEffect(() => {
    addVoteCounts();
    getUpvoteInfo();
  }, [question]);

  const handleUpvoteChange = async (e) => {
    if (downVote) {
      setUpVote(e.target.checked); setDownVote(false);
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/question/vote/updateVote`, { questionId: question.id, up: e.target.checked, userEmail: infos.email });
      setUpVoteCount(count => count + 1); setDownVoteCount(count => count - 1);
    } else if (upVote) {
      setUpVote(e.target.checked);
      await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/question/vote/deleteVote`, { data: { questionId: question.id, userEmail: infos.email } });
      setUpVoteCount(count => count - 1);
    } else {
      setUpVote(e.target.checked);
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/question/vote/saveVote`, { questionId: question.id, up: e.target.checked, userEmail: infos.email });
      setUpVoteCount(count => count + 1);
    }
  };

  const handleDownvoteChange = async (e) => {
    if (upVote) {
      setDownVote(e.target.checked); setUpVote(false);
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/question/vote/updateVote`, { questionId: question.id, up: !(e.target.checked), userEmail: infos.email });
      setUpVoteCount(count => count - 1); setDownVoteCount(count => count + 1);
    } else if (downVote) {
      setDownVote(e.target.checked);
      await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/question/vote/deleteVote`, { data: { questionId: question.id, userEmail: infos.email } });
      setDownVoteCount(count => count - 1);
    } else {
      setDownVote(e.target.checked);
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/question/vote/saveVote`, { questionId: question.id, up: !(e.target.checked), userEmail: infos.email });
      setDownVoteCount(count => count + 1);
    }
  };

  return (
    question
      ? <Card className={classes.card}>
          <Link to={'/qapage/' + encodeURIComponent(question.id)} className={classes.textPart}>
            {infos.isAdmin && question.urgent
              ? <NotificationImportant className={classes.forumIcon} />
              : <Forum className={classes.forumIcon} />
            }

            <div>
              <Typography className={classes.title}>{question.question}</Typography>
              <Typography variant='caption'>Publiée le {(new Date(question.date)).toLocaleDateString()}</Typography>
            </div>
          </Link>

          <Grid container className={classes.grid}>

            <Grid container item xs={6}>
              <Grid xs={3} md={12} item className={classes.iconStatus}>
                {question.expired
                  ? <Warning style={{ color: '#faa307' }} />
                  : (question.status === 'solved' ? <CheckCircle style={{ color: '#8ac926' }} /> : <Help color='action' />)
                }
              </Grid>
              <Grid xs={9} md={12} className={classes.captions}>
                {question.expired
                  ? <Typography variant="caption" style={{ color: '#faa307' }}>A mettre à jour</Typography>
                  : (question.status === 'solved'
                      ? <Typography variant="caption" style={{ color: '#8ac926' }}>Résolue</Typography>
                      : <Typography variant="caption" color='textSecondary'>En attente</Typography>)
                }
              </Grid>
            </Grid>

            <Grid container item xs={3}>
              <Grid item xs={4} md={12} className={classes.thumbIcons}>
                <Checkbox
                  checked={upVote}
                  onChange={handleUpvoteChange}
                  icon={<ThumbUpOutlined />}
                  checkedIcon={<ThumbUp />}
                  style={{ color: '#8ac926' }}
                />
              </Grid>
              <Grid item xs={8} md={12} className={classes.captions}>
                <Typography variant='caption' style={{ color: '#8ac926' }}>+{upVoteCount}</Typography>
              </Grid>
            </Grid>

            <Grid container item xs={3}>
              <Grid item xs={4} md={12} className={classes.thumbIcons}>
                <Checkbox
                  checked={downVote}
                  onChange={handleDownvoteChange}
                  icon={<ThumbDownOutlined />}
                  checkedIcon={<ThumbDown />}
                  style={{ color: '#ff595e' }}
                />
              </Grid>
              <Grid item xs={8} md={12} className={classes.captions}>
                <Typography variant='caption' style={{ color: '#ff595e' }}>-{downVoteCount}</Typography>
              </Grid>
            </Grid>

          </Grid>
        </Card>
      : null
  );
};

export default QuestionCard;
