import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import QuestionCard from './QuestionCard';

const useStyles = makeStyles((theme) => ({
  accordion: {
    backgroundColor: '#17a2b83d',
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightRegular,
  },
  bubble: {
    borderRadius: 15,
    backgroundColor: 'white',
    color: 'black',
    width: 30,
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginRight: theme.spacing(1),
  },
}));

const QuestionResults = ({ questions }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);
  const [page, setPage] = useState(1);
  const questionPerPage = 10;
  const totalPages = Math.ceil(questions.length / questionPerPage);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <div>
      <Accordion className={classes.accordion} expanded={expanded} onChange={(_, isExpanded) => setExpanded(isExpanded)}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
        >
          <div className={classes.bubble}>{questions.length}</div>
          <Typography className={classes.heading}>Questions</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} justifyContent='center'>
            {questions.length > 0
              ? questions.slice((page - 1) * questionPerPage, page * questionPerPage)
                .map((question) => (
                <Grid item xs={12} key={question.id}>
                  <QuestionCard question={question} />
                </Grid>
                ))
              : (
              <Grid item xs={12}>
                <Typography>Aucune question ne correspond à votre recherche</Typography>
              </Grid>)
            }
            {totalPages > 1 &&
              <Grid item>
                <Pagination count={totalPages} page={page} onChange={handlePageChange} variant='outlined' color='primary' />
              </Grid>
            }
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default QuestionResults;
