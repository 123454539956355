import React from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  Chip,
  Typography,
} from '@material-ui/core';
import AddTag from './AddTag';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0, 0, 0),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  chip: {
    backgroundColor: '#960D3B26' // C9A8B3 960D3B26 EED4DC D8D5DD
  }
}));

const TagList = (props) => {
  const classes = useStyles();
  const modifAllowed = props.modifAllowed;
  const createTagAllowed = props.createTagAllowed;
  const list = props.list;
  const entityType = props.entityType;
  const entityId = props.entityId;
  const setReload = props.setReload;

  const handleDelete = async (tagId) => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/${entityType}/deleteTag/${entityId}/${tagId}`);
      if (!response.data) throw Error(response.statusText);
      setReload(reload => !reload);
    } catch (error) {
      console.warn(error);
    }
  };

  return (
    <Box className={classes.root}>
      <Grid container spacing={1} alignItems='center'>
        {list && list.map((tag) =>
          <Grid item key={tag.id}>
            <Chip label={tag.value} onDelete={modifAllowed ? () => handleDelete(tag.id) : null} className={classes.chip} />
          </Grid>
        )}
      </Grid>
      {modifAllowed &&
        <AddTag createTagAllowed={createTagAllowed} entityType={entityType} entityId={entityId} setReload={setReload}/>
      }
      {!modifAllowed && (!list || list.length === 0) &&
      <Typography>Aucun tag associé</Typography>
      }
    </Box>
  );
};

export default TagList;
