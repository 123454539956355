import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import querystring from 'querystring';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    top: '10%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const LoginAcceptPage = ({ casToken, setAuth }) => {
  const [loading, setLoading] = useState(true);
  const classes = useStyles();

  useEffect(() => {
    const login = async () => {
      const casTicket = querystring.parse(casToken.substring(1)).ticket;
      if (casTicket !== undefined) {
        try {
        // Validate the CAS-provided ticket with the back-end
          const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/user/login/${casTicket}`);
          if (!response.data) throw Error(response.statusText);

          // Clear localStorage (useful in case of issues with older versions of Resa)
          localStorage.clear();

          // Store the jwtToken for future requests to the back-end
          const { jwtToken, ...infos } = await response.data;
          const auth = {
            isAuthenticated: true,
            jwt: jwtToken,
            infos
          };
          localStorage.setItem('auth', JSON.stringify(auth));
          axios.defaults.headers.common.Authorization = `Bearer ${jwtToken}`;
          setAuth(auth);
        } catch (error) {
          setLoading(false);
          console.warn(error);
        }
      }
    };
    login();
  });
  if (loading) {
    return (
    <div className={classes.container}><CircularProgress /></div>
    );
  }
  return (
    <div className={classes.container}>
      <Typography variant="h5" align="center">Une erreur s'est produite lors de l'authentification</Typography>
      <Link to="/login">Réessayer</Link>
    </div>
  );
};

export default LoginAcceptPage;
