import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import {
  Typography,
  Card,
  Grid,
  TextField,
  IconButton,
  Chip,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
} from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
import AddTag from '../Question/AddTag';
import AuthContext from '../Auth/auth.context';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
  },
  card: {
    [theme.breakpoints.up('sm')]: {
      width: 360,
    },
    margin: theme.spacing(3),
    padding: theme.spacing(3),
  },
  deleteButton: {
    textTransform: 'none',
    color: '#960d3b',
    borderColor: '#960d3b',
  },
  cancelButton: {
    textTransform: 'none',
  },
  chip: {
    backgroundColor: '#17a2b83d',
  },
}));

const TagPage = () => {
  const classes = useStyles();
  const { infos } = useContext(AuthContext);
  const [fetchTags, setFetchTags] = useState(false);
  const [tag, setTag] = useState({});
  const [tagRepresentative, setTagRepresentative] = useState({});
  const [synonyms, setSynonyms] = useState([]);
  const [newSynonym, setNewSynonym] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleAddTag = (newTag) => {
    setNewSynonym('');
    if (newTag.representative) {
      setTag(newTag);
      setTagRepresentative(newTag.representative);
    } else if (newTag.id) {
      setTag(newTag);
      setTagRepresentative(newTag);
    } else {
      saveTag(newTag.value);
    }
  };

  const saveTag = async (value) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/tag`,
        { value: value }
      );
      if (!response.data) throw Error(response.statusText);

      const data = await response.data;
      setTag(data);
      setTagRepresentative(data);
      setFetchTags((bool) => !bool);
    } catch (error) {
      console.warn(error);
    }
  };

  const searchSynonymes = async () => {
    if (tagRepresentative.id) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/tag/synonyms/${tagRepresentative.id}`
        );
        if (!response.data) throw Error(response.statusText);

        const data = await response.data;
        setSynonyms(data);
      } catch (error) {
        console.warn(error);
        localStorage.clear();
      }
    }
  };

  useEffect(() => {
    searchSynonymes();
  }, [tag, tagRepresentative]);

  const addSynonym = async () => {
    setNewSynonym('');
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/tag/addSynonym`,
        { tagId: tagRepresentative.id, synonymValue: newSynonym }
      );
      if (!response.data) throw Error(response.statusText);
      setFetchTags((bool) => !bool);
      searchSynonymes();
    } catch (error) {
      console.warn(error);
    }
  };

  const deleteTag = async () => {
    setTag({});
    if (tag.id === tagRepresentative.id) {
      setTagRepresentative({});
      setSynonyms([]);
      setNewSynonym('');
    }
    setDialogOpen(false);
    try {
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/tag/${tag.id}`
      );
      setFetchTags((bool) => !bool);
    } catch (error) {
      console.warn(error);
    }
  };

  return infos.isAdmin ? (
    <div className={classes.root}>
      <Card className={classes.card}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <AddTag
              createTagAllowed={infos.isAdmin}
              handleAddTag={handleAddTag}
              label="Parcourir les tags"
              fetchTags={fetchTags}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="outlined"
              className={classes.deleteButton}
              endIcon={<Delete />}
              onClick={() => setDialogOpen(true)}
              disabled={tag.id === undefined}
            >
              Supprimer ce tag
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">
              Synonymes
              {Object.keys(tagRepresentative).length !== 0 &&
                ' pour ' + tagRepresentative.value}
            </Typography>
          </Grid>

          <Grid container item xs={12} spacing={1} alignItems="center">
            {synonyms.map((syn, index) => (
              <Grid item key={index}>
                <Chip label={syn.value} className={classes.chip} />
              </Grid>
            ))}
          </Grid>

          <Grid container item xs={12} spacing={1} alignItems="center">
            <Grid item>
              <TextField
                value={newSynonym}
                onChange={(e) => setNewSynonym(e.target.value)}
                disabled={tagRepresentative.id === undefined}
                label="Ajouter un synonyme"
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <IconButton
                disabled={tagRepresentative.id === undefined}
                onClick={addSynonym}
              >
                <Add />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>

        <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
          <DialogTitle>
            Voulez-vous vraimez supprimer le tag {tag.value} et ses éventuels
            synonymes ?
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={() => setDialogOpen(false)}
              className={classes.cancelButton}
            >
              Annuler
            </Button>
            <Button
              onClick={deleteTag}
              className={classes.deleteButton}
              variant="outlined"
              autoFocus
            >
              Supprimer
            </Button>
          </DialogActions>
        </Dialog>
      </Card>
    </div>
  ) : null;
};

export default TagPage;
