import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Card,
  Checkbox,
  Grid,
  Collapse,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Avatar,
  Badge,
  Select,
  MenuItem,
} from '@material-ui/core';
import {
  Close,
  Create,
  Delete,
  Save,
  Star,
  ThumbDown,
  ThumbDownOutlined,
  ThumbUp,
  ThumbUpOutlined,
  Warning,
} from '@material-ui/icons';
import axios from 'axios';
import DialogInfos from '../General/DialogInfos';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  expiredCard: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: 'rgba(0, 0, 0, 0.10)',
    color: 'rgba(0, 0, 0, 0.26)',
  },
  noUpdateContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  answerContainer: {
    width: '65%',
  },
  adminText: {
    display: 'flex',
    alignItems: 'center',
  },
  starBadge: {
    color: '#fed700',
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  adminAvatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fed700',
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  userName: {
    marginLeft: theme.spacing(1),
  },
  noAnswerContainer: {
    [theme.breakpoints.up('md')]: {
      width: '35%',
    },
    display: 'flex',
    alignItems: 'center',
  },
  gridThumbs: {
    [theme.breakpoints.up('md')]: {
      width: '45%',
    },
  },
  thumbIcons: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
    },
  },
  captions: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
    },
  },
  endIcons: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '3%',
      width: '55%',
    },
    display: 'flex',
    alignItems: 'center',
  },
  updateCollapse: {
    width: '100%',
  },
  updateCollapseContainer: {
    width: '100%',
    marginTop: theme.spacing(4),
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  textField: {
    width: '100%',
    marginRight: theme.spacing(1),
  },
  select: {
    marginLeft: theme.spacing(1),
  },
  button: {
    textTransform: 'none',
    margin: theme.spacing(1, 0, 0, 0),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(0, 0, 0, 2),
    },
  },
}));

const AnswerCard = (props) => {
  const classes = useStyles();
  const answer = props.answer;
  const expired =
    answer.expirationDate && new Date(answer.expirationDate) < new Date();
  const setReload = props.setReload;
  const infos = props.infos;
  const [upVote, setUpVote] = useState(false);
  const [downVote, setDownVote] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [profile, setProfile] = useState({});
  const [textFieldValue, setTextFieldValue] = useState(answer.answer);
  const [expirationYear, setExpirationYear] = useState(
    answer.expirationDate
      ? new Date(answer.expirationDate).getFullYear()
      : 'Jamais'
  );

  const deleteAnswer = async () => {
    setConfirmDeleteOpen(false);
    setEditMode(false);
    await axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}/api/answer/deleteAnswer/${answer.id}`
    );
    setReload((reload) => !reload);
  };

  const modifyAnswer = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/answer/modifyAnswer`,
        {
          answerId: answer.id,
          answerValue: textFieldValue,
          expirationDate:
            expirationYear === 'Jamais'
              ? null
              : new Date(expirationYear, 6, 31),
        }
      );
      setReload((reload) => !reload);
      setTextFieldValue(textFieldValue);
      setEditMode(false);
    } catch (error) {
      console.warn(error);
    }
  };

  const handleUpvoteChange = async (e) => {
    if (downVote) {
      setUpVote(e.target.checked);
      setDownVote(false);
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/answer/vote/updateVote`,
        { answerId: answer.id, up: e.target.checked, userEmail: infos.email }
      );
      setReload((reload) => !reload);
    } else if (upVote) {
      setUpVote(e.target.checked);
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/answer/vote/deleteVote`,
        { data: { answerId: answer.id, userEmail: infos.email } }
      );
      setReload((reload) => !reload);
    } else {
      setUpVote(e.target.checked);
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/answer/vote/saveVote`,
        { answerId: answer.id, up: e.target.checked, userEmail: infos.email }
      );
      setReload((reload) => !reload);
    }
  };

  const handleDownvoteChange = async (e) => {
    if (upVote) {
      setDownVote(e.target.checked);
      setUpVote(false);
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/answer/vote/updateVote`,
        { answerId: answer.id, up: !e.target.checked, userEmail: infos.email }
      );
      setReload((reload) => !reload);
    } else if (downVote) {
      setDownVote(e.target.checked);
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/answer/vote/deleteVote`,
        { data: { answerId: answer.id, userEmail: infos.email } }
      );
      setReload((reload) => !reload);
    } else {
      setDownVote(e.target.checked);
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/answer/vote/saveVote`,
        { answerId: answer.id, up: !e.target.checked, userEmail: infos.email }
      );
      setReload((reload) => !reload);
    }
  };

  const getUpvoteInfo = () => {
    for (const index in Object.keys(answer.votes)) {
      const vote = answer.votes[index];
      const email = vote.user.email;
      if (infos.email === email) {
        setUpVote(vote.up);
        setDownVote(!vote.up);
        break;
      }
    }
  };

  const fetchWebserviceInfo = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/contact/webservice/${answer.user.email}`
      );
      if (!response.data) throw Error(response.statusText);

      const data = await response.data;
      if (data.length > 0) {
        setProfile(data[0]);
      }
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear();
      }
      console.warn(error);
    }
  };

  useEffect(() => {
    getUpvoteInfo();
    fetchWebserviceInfo();
  }, []);

  const confirmDeleteDialog = () => {
    return (
      <Dialog
        onClose={() => setConfirmDeleteOpen(false)}
        open={confirmDeleteOpen}
      >
        <DialogContent>
          <Typography>
            Êtes-vous sûr de vouloir supprimer cette réponse ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteAnswer} color="secondary">
            Oui
          </Button>
          <Button onClick={() => setConfirmDeleteOpen(false)} color="action">
            Non
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const userPresentation = (isAdmin) => {
    if (isAdmin) {
      return (
        <div className={classes.adminText}>
          <Badge
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            badgeContent={<Star className={classes.starBadge} />}
          >
            <Avatar className={classes.adminAvatar}>
              <Avatar
                alt={profile.displayName}
                src={profile.photoUrl}
                className={classes.avatar}
                style={expired ? { opacity: 0.55 } : null}
              />
            </Avatar>
          </Badge>
          <Typography variant="caption" className={classes.userName}>
            {(profile.displayName ? profile.displayName + ' - ' : '') +
              new Date(answer.publishedAt).toLocaleDateString()}
          </Typography>
        </div>
      );
    } else {
      return (
        <div className={classes.adminText}>
          <Avatar
            alt={profile.displayName}
            src={profile.photoUrl}
            className={classes.avatar}
            style={expired ? { opacity: 0.55 } : null}
          />
          <Typography variant="caption" className={classes.userName}>
            {(profile.displayName ? profile.displayName + ' - ' : '') +
              new Date(answer.publishedAt).toLocaleDateString()}
          </Typography>
        </div>
      );
    }
  };

  const editIcon = (userEmail) => {
    if (userEmail === infos.email) {
      return (
        <IconButton
          className={classes.editButton}
          onClick={() => setEditMode(!editMode)}
        >
          {editMode ? <Close /> : <Create />}
        </IconButton>
      );
    } else if (infos.isAdmin) {
      return (
        <IconButton
          color="secondary"
          onClick={() => setConfirmDeleteOpen(true)}
        >
          {' '}
          <Delete />{' '}
        </IconButton>
      );
    }
  };

  return answer ? (
    <Card className={expired ? classes.expiredCard : classes.card}>
      <div className={classes.noUpdateContainer}>
        <div className={classes.answerContainer}>
          {userPresentation(answer.user.isAdmin)}
          <Typography>{answer.answer}</Typography>
        </div>

        <div className={classes.noAnswerContainer}>
          <Grid container className={classes.gridThumbs}>
            <Grid container item xs={6}>
              <Grid item xs={6} md={12} className={classes.thumbIcons}>
                <Checkbox
                  checked={upVote}
                  onChange={handleUpvoteChange}
                  icon={<ThumbUpOutlined />}
                  checkedIcon={<ThumbUp />}
                  style={
                    expired
                      ? { color: 'rgba(138, 201, 38, 0.55)' }
                      : { color: '#8ac926' }
                  }
                />
              </Grid>
              <Grid item xs={6} md={12} className={classes.captions}>
                <Typography
                  variant="caption"
                  style={
                    expired
                      ? { color: 'rgba(138, 201, 38, 0.55)' }
                      : { color: '#8ac926' }
                  }
                >
                  +{answer.upvotes}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={6}>
              <Grid item xs={6} md={12} className={classes.thumbIcons}>
                <Checkbox
                  checked={downVote}
                  onChange={handleDownvoteChange}
                  icon={<ThumbDownOutlined />}
                  checkedIcon={<ThumbDown />}
                  style={
                    expired
                      ? { color: 'rgba(255, 89, 94, 0.55)' }
                      : { color: '#ff595e' }
                  }
                />
              </Grid>
              <Grid item xs={6} md={12} className={classes.captions}>
                <Typography
                  variant="caption"
                  style={
                    expired
                      ? { color: 'rgba(255, 89, 94, 0.55)' }
                      : { color: '#ff595e' }
                  }
                >
                  -{answer.downvotes}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid container className={classes.endIcons}>
            <Grid item xs={6} container justifyContent="flex-end">
              {expired && <Warning style={{ color: '#faa307' }} />}
            </Grid>
            <Grid item xs={6} container justifyContent="flex-end">
              {editIcon(answer.user.email)}
            </Grid>
          </Grid>
        </div>
      </div>

      <Collapse in={editMode} className={classes.updateCollapse}>
        <Grid container className={classes.updateCollapseContainer} spacing={1}>
          <Grid item container lg={10}>
            <Grid item xs={12} container alignItems="center">
              <Grid item xs={10} sm={11}>
                <TextField
                  className={classes.textField}
                  label="Modifier ma réponse"
                  multiline
                  variant="outlined"
                  value={textFieldValue}
                  onChange={(e) => setTextFieldValue(e.target.value)}
                />
              </Grid>
              <Grid item xs={2} sm={1}>
                <IconButton onClick={modifyAnswer}>
                  <Save />
                </IconButton>
              </Grid>
            </Grid>

            <Grid xs={12} item container alignItems="center">
              <Typography variant="caption">
                Réponse à réviser{' '}
                {expirationYear === 'Jamais' ? '' : ' à la rentrée '}
              </Typography>
              <Select
                value={expirationYear}
                onChange={(e) => setExpirationYear(e.target.value)}
                className={classes.select}
              >
                <MenuItem value={'Jamais'}>Jamais</MenuItem>
                <MenuItem value={2022}>2022</MenuItem>
                <MenuItem value={2023}>2023</MenuItem>
                <MenuItem value={2024}>2024</MenuItem>
                <MenuItem value={2025}>2025</MenuItem>
                <MenuItem value={2026}>2026</MenuItem>
              </Select>
              <DialogInfos subject={'expirationDateAnswer'} />
            </Grid>
          </Grid>
          <Grid item lg={2}>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => setConfirmDeleteOpen(true)}
              endIcon={<Delete />}
              className={classes.button}
            >
              Supprimer
            </Button>
          </Grid>
        </Grid>
      </Collapse>

      {confirmDeleteDialog()}
    </Card>
  ) : null;
};

export default AnswerCard;
