import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  TextField,
  Typography,
  InputAdornment,
  Button,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import TagList from '../Question/TagList';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 'calc(100vh - 240px)',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    position: 'relative',
    top: '10%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  searchField: {
    width: 'calc(min(90%, 700px))',
    marginTop: '5%',
    marginBottom: theme.spacing(3),
    borderRadius: 30,
    borderColor: '#960d3b',
  },
  tagList: {
    margin: theme.spacing(3, 0, 3, 0),
  },
  searchButton: {
    textTransform: 'none',
    marginBottom: theme.spacing(1),
    color: '#960d3b',
    borderColor: '#960d3b',
  },
  containerButtons: {
    marginBottom: '3%',
  },
  otherButtons: {
    textTransform: 'none',
    color: '#17a2b8',
    margin: theme.spacing(1),
  },
  warningMessage: {
    color: 'red',
    textAlign: 'center'
  }
}));

const SearchPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const [searchText, setSearchText] = useState('');
  const [tags, setTags] = useState([]);
  const [advancedSearch, setAdvancedSearch] = useState(false);

  const handleSearch = () => {
    if (!advancedSearch && searchText !== '') {
      history.push(`/results/${encodeURI(searchText)}`);
    }
    if (advancedSearch && tags.length !== 0) {
      history.push(`/results/${encodeURI(tags.map(tag => tag.value).join(' '))}`);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography variant='h5' align='center'>Le moteur de recherche pour t'orienter dans l'école</Typography>
        {advancedSearch
          ? <div className={classes.tagList}>
              <TagList createTagAllowed={false} tags={tags} setTags={setTags} />
            </div>
          : <TextField
            variant='outlined'
            placeholder='Question, thème, document...'
            autoFocus
            className={classes.searchField}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            InputProps={{
              startAdornment: <InputAdornment position='start'><Search color='action' /></InputAdornment>,
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleSearch();
            }}
          />
        }
        <Button
          variant='outlined'
          className={classes.searchButton}
          onClick={handleSearch}
        >
          Rechercher
        </Button>
        <div className={classes.containerButtons}>
          <Button
            className={classes.otherButtons}
            onClick={() => setAdvancedSearch(advanced => !advanced)}
          >
            {advancedSearch ? 'Recherche classique' : 'Recherche avancée'}
          </Button>
          <Button
            className={classes.otherButtons}
            onClick={() => history.push('/browse')}
          >
            Parcourir la FAQ
          </Button>
        </div>
        <Typography align='center'>Trouve un interloculteur, un site ou une réponse personnalisée !</Typography>
        <Typography className={classes.warningMessage}><br />Dans cette version pilote, les réponses sont apportées par tes Responsables de Promo. Note qu’elles ne peuvent se substituer au Règlement des études.</Typography>
      </div>
    </div>
  );
};

export default SearchPage;
