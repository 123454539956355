import React, { useState } from 'react';
import { IconButton, Dialog, DialogContent, DialogActions, Typography, makeStyles, DialogTitle } from '@material-ui/core';
import { Close, Info } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  infoIconButton: {
    marginLeft: theme.spacing(1),
  },
  infoIcon: {
    color: 'rgb(189, 189, 189)'
  }
}));

const values = {
  expirationDateAnswer: {
    title: 'Indiquez l\'année scolaire de fin de validité de votre réponse',
    content: 'La réponse sera notée comme potentiellement non fiable après l\'été de l\'année renseignée. Les modérateurs de cette plateforme recevront une alerte pour mettre à jour la réponse.',
    caption: 'Ex de réponses concernées : dates, informations sur un événement, spécificité d\'une promotion...'
  },
  alertUpdateNeeded: {
    content: 'Pour une question résolue il y a longtemps, ce bouton permet d\'indiquer que les réponses ne sont plus à jour. Un avertissement est placé en haut de cette page et les modérateurs de la plateforme sont alertés pour apporter une nouvelle réponse.'
  },
  tagForProfile: {
    title: 'Indiquez vos responsabilités',
    content: 'Associez à votre profil les tags qui vous correspondent le mieux. Vous pouvez chercher et sélectionner un tag existant ou créer un nouveau tag grâce au champ suivant. Votre profil apparaîtra alors dans les recherches des élèves concernant ces mots clés.'
  },
  groupContactForProfile: {
    title: 'Indiquez vos mailing lists',
    content: 'Ajoutez les mailing lists auxquelles vous appartenez et précisez leur rôle avec des tags. Vous figurerez dans les membres de ce contact générique sur la page dédiée. En associant des tags à ces adresses, elles apparaissent dans les recherches des élèves concernant ces mots clés.',
    caption: 'Si vous souhaitez que vos contacts passent au maximum par les mailing lists, associez des tags à vos mailing lists plutôt qu\'à vous-même.'
  },
  descriptionForProfile: {
    title: 'Complétez votre description sur mon compte',
    content: 'Les informations de contact et la description est issue de votre profil MonCompte.'
  },
  searchCollegue: {
    title: 'Complétez les profils de vos collègues',
    content: 'Recherchez les profils de vos collègues grâce à leur adresse mail CentraleSupélec. Vous pouvez alors leur ajoutez des tags et les associer à des adresses génériques. Vous répartissez ainsi les contacts potentiels des élèves au sein de votre direction !',
    caption: 'Attention : A chaque fois que vous ajoutez un tag à une personne, celle-ci reçoit un mail le lui indiquant. Elle peut ainsi maîtriser les tags qui lui sont associés.'
  },
};

const DialogInfos = ({ subject }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const infoDialog = () => {
    return (
      <Dialog onClose={() => setOpen(false)} open={open}>
        <DialogTitle>
          {values[subject].title}
        </DialogTitle>
        <DialogContent>
          <Typography align='justify'>{values[subject].content}</Typography>
          {subject === 'descriptionForProfile' &&
            <Typography align='justify'>Complétez vos informations sur <a href='https://moncompte.centralesupelec.fr/people/me/profil' target='_blank' rel='noreferrer'>moncompte.centralesupelec.fr</a> pour mettre à jour cette description.</Typography>
          }
          <Typography variant='caption' align='justify'>{values[subject].caption}</Typography>
        </DialogContent>
        <DialogActions>
          <IconButton onClick={() => setOpen(false)}> <Close/> </IconButton>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <div>
        <IconButton disabled={open} size='small' onClick={() => setOpen(true)} className={classes.infoIconButton}> <Info className={classes.infoIcon}/> </IconButton>
        {infoDialog()}
    </div>
  );
};

export default DialogInfos;
