import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Typography, TextField, Button } from '@material-ui/core';
import TagList from '../Question/TagList';
import axios from 'axios';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    textAlign: 'center',
    padding: theme.spacing(3, 5, 2, 5),
    margin: theme.spacing(3)
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  button: {
    textTransform: 'none',
    [theme.breakpoints.up('sm')]: {
      width: 300,
    },
    [theme.breakpoints.down('sm')]: {
      width: 200,
    },
    margin: '10px auto',
    fontWeight: 'bold',
    backgroundColor: '#960d3b',
    color: 'white',
    '&:hover': {
      backgroundColor: '#860b34',
    },
  },
  spacedInput: {
    marginTop: theme.spacing(2),
  }
}));

const NewWebsiteForm = ({ fetchSelectedWebsite, setDisplayForm, website, setReload }) => {
  const classes = useStyles();
  const [title, setTitle] = useState(website ? website.title : '');
  const [link, setLink] = useState(website ? website.link : '');
  const [tags, setTags] = useState(website ? website.tags : []);
  const [error, setError] = useState('');

  const createTag = async (value) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/tag`, { value: value });
      return res.data;
    } catch (error) {
      console.warn(error);
    }
  };

  const submit = async (e) => {
    e.preventDefault();
    if (title && link) {
      const tagIds = [];
      for (const tag of tags) {
        if (tag.id) {
          tagIds.push(tag.id);
        } else {
          const newTag = await createTag(tag.value);
          tagIds.push(newTag.id);
        }
      }
      const websiteForm = {
        ...website,
        title,
        link,
        tagIds
      };
      try {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/website`, websiteForm);
        fetchSelectedWebsite(res.data.id);
        setDisplayForm(false);
        setLink('');
        setTitle('');
        setTags([]);
        setReload(reload => !reload);
      } catch (error) {
        console.log(error);
        setError("Erreur lors de l'ajout du nouveau site, veuillez rééssayer");
      };
    } else {
      setError('Veuillez renseigner toutes les informations nécessaires');
    }
  };
  return (
    <form className={classes.root}>
        <Card className={classes.card}>
            <Typography className={classes.title} variant="h4" >{website ? 'Modifier le ' : 'Ajouter un nouveau '} site</Typography>
            <TextField label="Titre du site" className={classes.spacedInput} variant="outlined" value={title} onChange={e => setTitle(e.target.value)} required/>
            <TextField label="URL du site" className={classes.spacedInput} variant="outlined" value={link} onChange={e => setLink(e.target.value)} required/>
            <TagList createTagAllowed={true} tags={tags} setTags={setTags} />
            <Button variant="contained" className={classes.button} onClick={submit}>{website ? 'Modifier' : 'Ajouter'}</Button>
            {error && <Typography>{error}</Typography>}
        </Card>
    </form>
  );
};

export default NewWebsiteForm;
