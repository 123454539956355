import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import AuthContext from '../Auth/auth.context';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Card,
  Checkbox,
  IconButton,
  Button,
  Grid,
  Collapse,
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  Avatar,
} from '@material-ui/core';
import {
  CheckCircle,
  Create,
  Delete,
  Help,
  Save,
  Warning,
  ThumbDown,
  ThumbDownOutlined,
  ThumbUp,
  ThumbUpOutlined,
  Close,
} from '@material-ui/icons';
import axios from 'axios';
import TagList from '../StaffProfile/TagList';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  noUpdateContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
  },
  questionContainer: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 'calc(100% - 230px)',
    },
  },
  questionLong: {
    marginTop: theme.spacing(1),
  },
  grid: {
    width: '100%',
    margin: theme.spacing(1, 0, 0, 0),
    [theme.breakpoints.up('md')]: {
      margin: 0,
      width: 230,
    },
  },
  doubleCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  centerAlign: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    marginRight: theme.spacing(1),
  },
  centerButton: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
  },
  solvingButton: {
    textTransform: 'none',
    marginRight: theme.spacing(1),
  },
  updateCollapse: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  textfieldContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  textField: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
}));

const QuestionCard = (props) => {
  const classes = useStyles();
  const [question, setQuestion] = useState(props.question);
  const [upVote, setUpVote] = useState(false);
  const { infos } = useContext(AuthContext);
  const history = useHistory();
  const [downVote, setDownVote] = useState(false);
  const [profile, setProfile] = useState({});
  const setReload = props.setReload;
  const [editMode, setEditMode] = useState(false);
  const [textFieldValue, setTextFieldValue] = useState(question.questionLong);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  const handleTextFieldChange = (e) => {
    setTextFieldValue(e.target.value);
    setReload((reload) => !reload);
  };
  const modifyQuestion = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/question/modifyQuestion`,
        { questionId: question.id, questionLongValue: textFieldValue }
      );
      setReload((reload) => !reload);
      setTextFieldValue(textFieldValue);
      setEditMode(false);
    } catch (error) {
      console.warn(error);
    }
  };

  const deleteQuestion = async () => {
    await axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}/api/question/deleteQuestion/${question.id}`
    );
    history.push('/');
  };
  const handleStatusChange = async () => {
    const value = question.status === 'solved' ? 'pending' : 'solved';
    await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/question/updateStatus/${question.id}/${value}`
    );
    setQuestion((prevQuestion) => ({
      ...prevQuestion,
      status: value,
    }));
  };

  const handleUpvoteChange = async (e) => {
    if (downVote) {
      setUpVote(e.target.checked);
      setDownVote(false);
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/question/vote/updateVote`,
        {
          questionId: question.id,
          up: e.target.checked,
          userEmail: infos.email,
        }
      );
      setReload((reload) => !reload);
    } else if (upVote) {
      setUpVote(e.target.checked);
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/question/vote/deleteVote`,
        { data: { questionId: question.id, userEmail: infos.email } }
      );
      setReload((reload) => !reload);
    } else {
      setUpVote(e.target.checked);
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/question/vote/saveVote`,
        {
          questionId: question.id,
          up: e.target.checked,
          userEmail: infos.email,
        }
      );
      setReload((reload) => !reload);
    }
  };

  const handleDownvoteChange = async (e) => {
    if (upVote) {
      setDownVote(e.target.checked);
      setUpVote(false);
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/question/vote/updateVote`,
        {
          questionId: question.id,
          up: !e.target.checked,
          userEmail: infos.email,
        }
      );
      setReload((reload) => !reload);
    } else if (downVote) {
      setDownVote(e.target.checked);
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/question/vote/deleteVote`,
        { data: { questionId: question.id, userEmail: infos.email } }
      );
      setReload((reload) => !reload);
    } else {
      setDownVote(e.target.checked);
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/question/vote/saveVote`,
        {
          questionId: question.id,
          up: !e.target.checked,
          userEmail: infos.email,
        }
      );
      setReload((reload) => !reload);
    }
  };

  const fetchWebserviceInfo = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/contact/webservice/${question.user.email}`
      );
      if (!response.data) throw Error(response.statusText);

      const data = await response.data;
      if (data.length > 0) {
        setProfile(data[0]);
      }
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear();
      }
      console.warn(error);
    }
  };

  const getUpvoteInfo = () => {
    for (const index in Object.keys(question.votes)) {
      const vote = question.votes[index];
      const email = vote.user.email;
      if (infos.email === email) {
        setUpVote(vote.up);
        setDownVote(!vote.up);
        break;
      }
    }
  };

  useEffect(() => {
    getUpvoteInfo();
    fetchWebserviceInfo();
  }, []);

  useEffect(() => {
    setQuestion(props.question);
  }, [props.question]);

  const confirmDeleteDialog = () => {
    return (
      <Dialog
        onClose={() => setConfirmDeleteOpen(false)}
        open={confirmDeleteOpen}
      >
        <DialogContent>
          <Typography>
            Êtes-vous sûr de vouloir supprimer cette question ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteQuestion} color="secondary">
            Oui
          </Button>
          <Button onClick={() => setConfirmDeleteOpen(false)} color="action">
            Non
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return question ? (
    <Card className={classes.card}>
      <div className={classes.noUpdateContainer}>
        <div className={classes.questionContainer}>
          <Typography variant="h6">{question.question}</Typography>
          <div className={classes.centerAlign}>
            <Avatar
              alt={profile.displayName}
              src={profile.photoUrl}
              className={classes.avatar}
            />
            <Typography variant="caption">
              {(profile.displayName ? profile.displayName + ' - ' : '') +
                new Date(question.date).toLocaleDateString()}
            </Typography>
          </div>
          <Typography className={classes.questionLong}>
            {question.questionLong}
          </Typography>

          <Collapse in={!editMode}>
            <TagList
              modifAllowed={false}
              createTagAllowed={false}
              list={question.tags}
              entityType={'question'}
              entityId={question.id}
              setReload={setReload}
            />
          </Collapse>
        </div>

        <Grid container className={classes.grid} spacing={1}>
          <Grid container item xs={12} sm={6} md={12}>
            <Grid item xs={6} className={classes.doubleCenter}>
              {question.expired ? (
                <Warning style={{ color: '#faa307' }} />
              ) : question.status === 'solved' ? (
                <CheckCircle style={{ color: '#8ac926' }} />
              ) : (
                <Help color="action" />
              )}
            </Grid>
            <Grid item xs={2} sm={3} className={classes.center}>
              <Checkbox
                checked={upVote}
                onChange={handleUpvoteChange}
                icon={<ThumbUpOutlined />}
                checkedIcon={<ThumbUp />}
                style={{ color: '#8ac926' }}
              />
            </Grid>
            <Grid item xs={2} sm={3} className={classes.center}>
              <Checkbox
                checked={downVote}
                onChange={handleDownvoteChange}
                icon={<ThumbDownOutlined />}
                checkedIcon={<ThumbDown />}
                style={{ color: '#ff595e' }}
              />
            </Grid>
            <Grid item xs={6} className={classes.center}>
              {question.expired ? (
                <Typography variant="caption" style={{ color: '#faa307' }}>
                  A mettre à jour
                </Typography>
              ) : question.status === 'solved' ? (
                <Typography variant="caption" style={{ color: '#8ac926' }}>
                  Résolue
                </Typography>
              ) : (
                <Typography variant="caption" color="textSecondary">
                  En attente
                </Typography>
              )}
            </Grid>
            <Grid item xs={2} sm={3} className={classes.center}>
              <Typography variant="caption" style={{ color: '#8ac926' }}>
                +{question.upvotes}
              </Typography>
            </Grid>
            <Grid item xs={2} sm={3} className={classes.center}>
              <Typography variant="caption" style={{ color: '#ff595e' }}>
                -{question.downvotes}
              </Typography>
            </Grid>
          </Grid>

          {(question.user.email === infos.email || infos.isAdmin) && (
            <Grid item xs={12} sm={6} md={12} className={classes.centerButton}>
              <Button
                onClick={handleStatusChange}
                variant="outlined"
                className={classes.solvingButton}
              >
                {question.status === 'solved'
                  ? 'Rouvrir la question'
                  : 'Clore la question'}
              </Button>
              <IconButton
                className={classes.editButton}
                onClick={() => setEditMode(!editMode)}
              >
                {editMode ? <Close /> : <Create />}
              </IconButton>
            </Grid>
          )}
        </Grid>
      </div>

      <Collapse in={editMode} className={classes.updateCollapse}>
        <Grid
          container
          spacing={2}
          alignItems="flex-end"
          justifyContent="space-around"
        >
          <Grid item xs={12} lg={4}>
            <TagList
              modifAllowed={true}
              createTagAllowed={infos.isAdmin || infos.status !== 'student'}
              list={question.tags}
              entityType={'question'}
              entityId={question.id}
              setReload={setReload}
            />
          </Grid>
          <Grid item xs={12} lg={5}>
            <div className={classes.textfieldContainer}>
              <TextField
                className={classes.textField}
                label="Modifier ma question"
                multiline
                variant="outlined"
                value={textFieldValue}
                onChange={handleTextFieldChange}
                disabled={!(infos.email === question.user.email)}
              />
              <IconButton onClick={modifyQuestion}>
                <Save />
              </IconButton>
            </div>
          </Grid>
          <Grid item xs={12} lg={2}>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => setConfirmDeleteOpen(true)}
              endIcon={<Delete />}
              className={classes.button}
            >
              Supprimer
            </Button>
          </Grid>
        </Grid>
      </Collapse>

      {confirmDeleteDialog()}
    </Card>
  ) : null;
};

export default QuestionCard;
