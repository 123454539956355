import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

import SearchPage from './components/components/SearchEngine/SearchPage';
import { useAuth } from './components/components/Auth/auth.hooks';
import AuthContext from './components/components/Auth/auth.context';
import LoginPage from './components/components/Auth/LoginPage';
import LoginAcceptPage from './components/components/Auth/LoginAcceptPage';
import NewQuestionPage from './components/components/Question/NewQuestionPage';
import PrivateRoute from './components/components/Auth/PrivateRoute';
import PublicRoute from './components/components/Auth/PublicRoute';
import ProfilePage from './components/components/StaffProfile/ProfilePage';
import ResultsPage from './components/components/SearchEngine/ResultsPage';
import SitesPage from './components/components/Sites/SitesPage';
import BrowseFAQ from './components/components/FAQ/BrowseFAQ';
import TagPage from './components/components/Tags/TagPage';
import QuestionAnswerPage from './components/components/QuestionAnswers/QuestionAnswersPage';

const App = () => {
  const { auth, setAuth } = useAuth();
  return auth
    ? (<AuthContext.Provider value ={auth}>
      <Router>
        <Switch>
          <PublicRoute path='/login' component={LoginPage} />
          <PublicRoute
              path='/loginAccept/'
              component={props => (
                <LoginAcceptPage
                  casToken={props.location.search}
                  setAuth={setAuth}
                />
              )}
            />
          <PrivateRoute path="/search" component={SearchPage} />
          <PrivateRoute path= "/qapage/:id" component={QuestionAnswerPage} />
          <PrivateRoute path="/results/:search" component={ResultsPage} />
          <PrivateRoute path="/question/new" component={NewQuestionPage} />
          <PrivateRoute path='/profile/:emailEncode' component={ProfilePage} />
          <PrivateRoute path='/sites' component={SitesPage} />
          <PrivateRoute path='/browse' component={BrowseFAQ} />
          <PrivateRoute path='/tags' component={TagPage} />
          <PrivateRoute path='/' component={SearchPage} />
        </Switch>
      </Router>
    </AuthContext.Provider>)
    : (<CircularProgress />);
};

export default App;
