import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  TextField,
  Button
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import DialogInfos from '../General/DialogInfos';
import AuthContext from '../Auth/auth.context';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1, 2, 2, 2),
    width: 360,
    [theme.breakpoints.down('xs')]: {
      width: '80%'
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'end',
  },
  textField: {
    width: 280,
    [theme.breakpoints.down('xs')]: {
      width: '90%'
    }
  },
  searchButton: {
    marginLeft: theme.spacing(1),
  }
}));

const SearchColleague = () => {
  const classes = useStyles();
  const history = useHistory();
  const { infos } = useContext(AuthContext);
  const modifAllowed = infos.isAdmin || infos.status !== 'student';
  const [searchEmail, setSearchEmail] = useState('');
  const [error, setError] = useState('');

  const handleChange = (e) => {
    setSearchEmail(e.target.value);
    setError('');
  };

  const handleSearch = () => {
    if (searchEmail.includes('@')) {
      history.push('/profile/' + encodeURIComponent(searchEmail));
    } else {
      setError('Email invalide');
    }
  };

  return (
    <Card className={classes.root}>
      <TextField
        value={searchEmail}
        onChange={handleChange}
        label='Rechercher un collègue'
        placeholder='Email'
        error={error !== ''}
        helperText={error}
        className={classes.textField}
        onKeyDown={(e) => {
          if (e.key === 'Enter') handleSearch();
        }}
      />
      <Button onClick={handleSearch} className={classes.searchButton}>
        <Search />
      </Button>
      {modifAllowed && <DialogInfos subject={'searchCollegue'} />}
    </Card>
  );
};

export default SearchColleague;
