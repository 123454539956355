import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Typography, Button, Grid } from '@material-ui/core';

import AuthContext from '../Auth/auth.context';
import logo from '../../images/myway-logo.png';

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 60,
  },
  img: {
    width: 150,
    marginRight: theme.spacing(5),
  },
  button: {
    marginLeft: theme.spacing(5),
    textTransform: 'none',
    fontWeight: 'bold',
    backgroundColor: '#960d3b',
    color: 'white',
    '&:hover': {
      backgroundColor: '#860b34',
    },
  },
}));

const Topbar = () => {
  const classes = useStyles();
  const { infos } = useContext(AuthContext);
  const [title, setTitle] = useState('');

  const logout = () => {
    localStorage.removeItem('auth');
    window.location.href = `${process.env.REACT_APP_LOGOUT_URL}?service=${process.env.REACT_APP_LOGOUT_SERVICE}`;
  };

  useEffect(() => {
    const setResponsiveness = () => {
      if (window.innerWidth > 960) {
        setTitle(`Bonjour ${infos.firstName} ${infos.lastName}`);
      } else if (window.innerWidth > 600) {
        setTitle(`${infos.firstName} ${infos.lastName}`);
      } else {
        setTitle('');
      }
    };
    setResponsiveness();
    window.addEventListener('resize', () => setResponsiveness());
  }, []);

  return (
    <AppBar className={classes.appBar}>
      <Grid container alignItems="center">
        <Grid item xs={6} sm={4} md={3} container justifyContent="center">
          <img src={logo} alt="logo" className={classes.img} />
        </Grid>
        {title !== '' && (
          <Grid item sm={4} md={6} container justifyContent="center">
            <Typography color="textPrimary" align="center">
              {title}
            </Typography>
          </Grid>
        )}
        <Grid item xs={6} sm={4} md={3} container justifyContent="center">
          <Button
            variant="contained"
            className={classes.button}
            onClick={logout}
          >
            Se déconnecter
          </Button>
        </Grid>
      </Grid>
    </AppBar>
  );
};

export default Topbar;
