import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SearchFAQ from './SearchFAQ';
import QuestionResults from '../SearchEngine/QuestionResults';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  resultContainer: {
    margin: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      width: '70%'
    }
  }
}));

const BrowseFAQ = () => {
  const classes = useStyles();
  const [questions, setQuestions] = useState([]);

  return (
    <div className={classes.root}>
      <SearchFAQ setQuestions={setQuestions} />
      <div className={classes.resultContainer}>
        <QuestionResults questions={questions} />
      </div>
    </div>
  );
};

export default BrowseFAQ;
