import React, { useContext, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Tabs,
  Tab,
  Paper,
} from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import AuthContext from '../Auth/auth.context';

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      width: '100%',
      maxWidth: 40,
      backgroundColor: '#960d3b',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 90,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    padding: theme.spacing(0, 2, 0, 2),
    borderRadius: 30,
  },
  tab: {
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(15),
  },
  selectedTab: {
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(18),
    color: '#960d3b',
  },
}));

const NavTabs = () => {
  const classes = useStyles();
  const history = useHistory();
  const { infos } = useContext(AuthContext);
  const path = useLocation().pathname.split('/')[1];
  const [tab, setTab] = useState(() => {
    switch (path) {
      case 'search' : return 0;
      case 'profile' : return 1;
      case 'sites' : return 2;
      case 'browse' : return 3;
      case 'tags' : return 4;
      default : return 0;
    };
  });
  const context = useContext(AuthContext);

  const handleChange = (_, newTab) => {
    setTab(newTab);
    switch (newTab) {
      case 0: history.push('/search'); break;
      case 1: history.push('/profile/' + encodeURIComponent(context.infos.email)); break;
      case 2: history.push('/sites'); break;
      case 3: history.push('/browse'); break;
      case 4: history.push('/tags'); break;
      default: history.push('/');
    }
  };

  return (
    <div className={classes.root}>
      <Paper variant='outlined' className={classes.paper}>
        <StyledTabs value={tab} onChange={handleChange}>
          <Tab className={tab === 0 ? classes.selectedTab : classes.tab} disableRipple label='Ask' />
          <Tab className={tab === 1 ? classes.selectedTab : classes.tab} disableRipple label='Profil' />
          {infos.isAdmin && <Tab className={tab === 2 ? classes.selectedTab : classes.tab} disableRipple label='Sites' />}
          {infos.isAdmin && <Tab className={tab === 3 ? classes.selectedTab : classes.tab} disableRipple label='FAQ' />}
          {infos.isAdmin && <Tab className={tab === 4 ? classes.selectedTab : classes.tab} disableRipple label='Tags' />}
        </StyledTabs>
      </Paper>
    </div>
  );
};

export default NavTabs;
